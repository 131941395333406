import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaKursov = () => {
  return (
    <>
      <Helmet>
        <title>Съемка курсов в Москве цена от видеопродакшена Eldandic</title>
        <meta name="description" content="Съемка курсов в Москве цена услуг от видеопродакшена Eldandic. Заказать профессиональную запись обучающих видеокурсов стоимость онлайн от студии." />
        <meta name="keywords" content="съемка курсов, онлайн, москва, обучающих, студия" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка курсов'>
          <p>В современном мире возможности для обучения стали безграничными благодаря доступности онлайн-курсов. Создание качественного обучающего контента требует профессионального подхода и технической оснащенности. Видеопродакшн Elandic предлагает полный спектр услуг по съемке образовательных и тренинговых курсов в Москве, обеспечивая высокое качество видео и звука для эффективного дистанционного образования.</p>

          <p>Мы понимаем, насколько важно донести до аудитории суть обучающего материала ясно и эффективно. Вот почему наша команда профессиональных операторов, звукоинженеров и редакторов работает с каждым клиентом индивидуально, разрабатывая концепцию и сценарий таким образом, чтобы конечный продукт был не только информативным, но и увлекательным.</p>

          <p>Наши услуги включают разработку сценариев, подбор локаций, съемку и постпродакшн, обеспечивая полный цикл создания курса от идеи до финального продукта. Ценообразование в студии Elandic гибкое и зависит от множества факторов, включая длительность курса, сложность съемок и дополнительные услуги постпродакшена. Наша миссия – сделать высококачественное образование доступным, предоставляя нашим клиентам прозрачные и конкурентоспособные цены.</p>

          <p>Выбор видеопродакшена Elandic для съемки ваших образовательных курсов в Москве – это гарантия того, что ваш контент будет не только информативным, но и визуально привлекательным, способным удержать внимание аудитории. </p>

          <p>Мы гордимся возможностью вносить свой вклад в образовательный процесс и создавать продукт, который поможет людям по всему миру расти профессионально и личностно. Доверьте производство вашего следующего курса профессионалам из Elandic и убедитесь в том, что качество и доступность – это не всегда компромисс.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaKursov);
